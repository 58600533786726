::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0) !important;
    width: 6px;
    height: 6px;
    z-index: 999999;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 16px;
    border: 0px solid inherit;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

/* scrollbar when element is hovered */
:hover::-webkit-scrollbar-thumb {
    background-color: #a0a0a5;
    border: 4px solid inherit;
}

/* scrollbar when scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid inherit;
}
